<template>
    <div>
      <button style="display: flex; background-color: white; align-items: center; margin-left:1rem; margin-top:4px; border: none" title="Regresar" v-on:click="comeback()"><box-icon name='arrow-back'></box-icon></button>
    </div>
    <div class="cont-data-postulantes flex column p-3" v-for="(item, i) in dataPostulate" :key="i + 1">
      <div style="display: flex; background-color: #ff7a0b; padding: 2%; align-items: center">
        <div v-if="item.user?.photo" style="width: 10%; margin: 10px">
          <img
            class="rounded-circle"
            :src="item.user?.photo.link"
            alt="Imagen de usuario"
            loading="lazy"
            decoding="async"
            style="width: 100%; aspect-ratio: 1/1; object-fit: cover; object-position: center"
          />
        </div>
        <div v-else style="width: 10%; margin: 10px">
          <img src="/images/profile.jpg" alt="Logo Company" class="rounded-circle" loading="lazy" decoding="async" style="width: 90%; aspect-ratio: 1/1; object-fit: cover; object-position: center" />
        </div>
        <div style="display: flex; flex-direction: column; width: 90%">
          <div style="display: flex; justify-content: left; align-items: center">
            <span style="font-size: 18px; font-weight: bold; font-family: Poppins; text-transform: uppercase; color: white">
              {{ item.student.name }}
              {{ item.student.lastname }}
              {{ item.student.secondlastname }}
            </span>
          </div>
          <div style="display: flex; justify-content: space-between; align-items: center">
            <div style="flex: 2" v-show="item.student.status === 'preuniversitario'">
              <span style="font-size: 18px; font-weight: bold; font-family: Poppins; color: white"> Estudiante de Bachillerato </span>
              <small style="color: white"> / </small>
              <span style="font-size: 18px; font-weight: bold; font-family: Poppins; color: white"> {{ getEdad(item.student.date) }} años </span>
            </div>
            <div style="flex: 1" v-show="item.student.status === 'universitario'">
              <span style="font-size: 18px; font-weight: bold; font-family: Poppins; color: white"> Estudiante Universitario </span>
              <span style="color: white"> / </span>
              <span style="font-size: 18px; font-weight: bold; font-family: Poppins; color: white"> {{ getEdad(item.student.date) }} años </span>
            </div>
            <div style="flex: 1; display: flex; justify-content: flex-end">
              <div style="display: flex; justify-content: center; align-items: center">
                <div v-if="item.student.linkedin">
                  <a class="J-btn auto" title="LinkedIn" target="_blank" v-on:click="openLinkedin(item.student.linkedin)">
                    <box-icon color="white" type="logo" name="linkedin-square"></box-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Nueva navegación -->
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="documents-tab" data-bs-toggle="tab" data-bs-target="#subir-tab-pane" type="button" role="tab" aria-controls="subir-tab-pane" aria-selected="true">
            Datos de Administración
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="false">
            Datos Personales
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">
            Información Escolar
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">
            Idiomas
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="disabled-tab" data-bs-toggle="tab" data-bs-target="#disabled-tab-pane" type="button" role="tab" aria-controls="disabled-tab-pane" aria-selected="false">
            Hobbies
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="experiencia-tab" data-bs-toggle="tab" data-bs-target="#experiencia-tab-pane" type="button" role="tab" aria-controls="experiencia-tab-pane" aria-selected="false">
            Experiencia
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="documents-tab" data-bs-toggle="tab" data-bs-target="#documents-tab-pane" type="button" role="tab" aria-controls="documents-tab-pane" aria-selected="false">
            Documentos Becario
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="documents-tab" data-bs-toggle="tab" data-bs-target="#convenios-tab-pane" type="button" role="tab" aria-controls="convenios-tab-pane" aria-selected="false">
            Convenios
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <!-- Datos de Administración -->
        <div class="tab-pane fade show active" id="subir-tab-pane" role="tabpanel" aria-labelledby="subir-tab" tabindex="7" style="max-height: 420px; overflow: scroll">
          <!-- Muestra los Datos de Administración -->
          <div style="display: flex; flex-direction: column; padding: 2%">
            <div style="display: flex; justify-content: space-between; flex-direction: row; margin-bottom: 2%">
              <div>
                <span style="font-size: 20px; font-family: Poppins"> Datos de Administración </span>
              </div>
            </div>
            <div style="display: flex; width: 100%; height: 4rem">
              <span style="flex: 1"><b>ID:</b> {{ item.idAdministration }}</span>
              <span style="flex: 1"><b>Posición:</b> {{ item.position }}</span>
              <span style="flex: 1"><b>Beca Mensual: </b>{{ item.salary }}</span>
              
            </div>
            <div style="display: flex; width: 100%; height: 4rem">
              <span style="flex: 1"><b>Matricula:</b> {{ item.schoolEnrollment }}</span>
              <span style="flex: 1"><b>Unidad/División:</b> {{ item.company }}</span>
              <span style="flex: 1"><b>Mentor:</b> {{ item.mentor }}</span>
            </div>
            <div style="display: flex; width: 100%; height: 4rem">
              <span style="flex: 1"><b>Semestre:</b> {{ item.schoolSemester }}</span>
              <span style="flex: 1"><b>Departamento:</b> {{ item.department }}</span>
              <span style="flex: 1"><b>Email del mentor:</b> {{ item.emailMentor}}</span>
            </div>
            <div style="display: flex; width: 100%; height: 4rem">
              <span style="flex: 1"><b>Titulación: </b>{{ item.titulacion }}</span>
              <span style="flex: 1"><b>Ubicación: </b>{{ item.ubicationCompany }}</span>
              <span style="flex: 1"><b>Banco:</b> {{ item.bank }}</span>
            </div>
            <div style="display: flex; width: 100%; height: 4rem">
              <span style="flex: 1"><b>Núm. póliza escolar:</b> {{ item.policyNumberSchool }}</span>
              <span style="flex: 1"><b>Centro de costos:</b> {{ item.costCenter }}</span>
              <span style="flex: 1"><b>Número de cuenta: </b>{{ item.bankAccount }}</span>
            </div>
            <div style="display: flex; width: 100%; height: 4rem">
              <span style="flex: 1"><b>Número de póliza: </b>{{ item.policyNumber }}</span>
              <span style="flex: 1"><b>Nombre centro de costos: </b>{{ item.nameCostCenter }}</span>
              <div v-show="!item.dateHired" style="flex: 1"><b>Fecha de ingreso:</b></div>
              <div v-show="item.dateHired" style="flex: 1">
                <span><b>Fecha de ingreso:</b>
                  {{ moment.utc(item.dateHired).format('DD/MM/YYYY').split('T')[0] }}
                </span>
              </div>
            </div>
            <div style="display: flex; width: 100%; height: 4rem">
              <span style="flex: 1"><b>Aseguradora: </b>{{ item.insuranceCarrier }}</span>
              <span style="flex: 1"><b>Email corporativo: </b>{{ item.emailCorporative }}</span>
              <div v-show="!item.dateFirstPeriod" style="flex: 1"><b>Termino 1er Convenio:</b></div>
              <div v-show="item.dateFirstPeriod" style="flex: 1">
                <span style="flex: 1" ><b>Termino 1er Convenio:</b>
                  {{ moment.utc(item.dateFirstPeriod).format("DD/MM/YYYY").split('T')[0] }}
                </span>
              </div>
            </div>
            <div style="display: flex; width: 100%; height: 4rem">
              <span style="flex: 1"><b>RFC: </b>{{ item.rfc }}</span>
              <span style="flex: 1"><b>Status becario: </b>{{ item.statusActually }}</span>
              <div v-show="!item.secondEndDate" style="flex: 1"><b>Termino 2do Convenio:</b></div>
              <div v-show="item.secondEndDate" style="flex: 1">
                <span style="flex: 1"><b>Termino 2do Convenio:</b>
                  {{ moment.utc(item.secondEndDate).format("DD/MM/YYYY").split('T')[0] }}
                </span>
              </div>
            </div>
            <div style="display: flex; width: 100%; height: 4rem">
              <span style="flex: 1"></span>
              <span style="flex: 1"><b>Antigüedad de practicas: </b>{{ getAntiguedad(item.dateHired, item.dateSecondPeriod) }} días</span>
              <div v-show="!item.dateSecondPeriod" style="flex: 1"><b>Fecha de baja:</b></div>
              <div v-show="item.dateSecondPeriod" style="flex: 1">
                <span style="flex: 1"><b>Fecha de baja:</b>
                  {{ moment.utc(item.dateSecondPeriod).format("DD/MM/YYYY").split('T')[0] }}
                </span>
              </div>
            </div>
            <hr />
            <span style="font-size: 16px; font-family: Poppins; margin-bottom: 1rem"><b>Contactos de emergencia </b></span>
            <div style="display: flex; width: 100%; height: 4rem">
              <span style="flex: 1"><b>Nombre: </b>{{ item.nameContact }}</span>
              <span style="flex: 1"><b>Parentesco: </b>{{ item.relationship }}</span>
              <span style="flex: 1"><b>Teléfono:</b> {{ item.numberContact }}</span>
            </div>
            <div style="display: flex; width: 100%; height: 4rem">
              <span style="flex: 1"><b>Nombre: </b>{{ item.nameContactTwo }}</span>
              <span style="flex: 1"><b>Parentesco: </b>{{ item.relationshipTwo }}</span>
              <span style="flex: 1"><b>Teléfono:</b> {{ item.numberContactTwo }}</span>
            </div>
            <hr />
            <div style="display: flex; width: 100%; height: 4rem">
              <span style="flex: 1"><b>Evaluación Final: </b>{{ item.evaluation }}</span>
            </div>
            <hr />
            <div style="display: flex; width: 100%">
              <span class="flex"
                ><b>Comentarios: </b>
                <span v-html="item.comments"></span>
              </span>
            </div>
            <hr />
          </div>
        </div>
        <!-- Información Personal -->
        <div class="tab-pane fade" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
          <div style="padding: 2rem">
            <div style="display: flex; flex-wrap: wrap">
              <div style="display: flex; width: 100%; height: 4rem">
                <span style="flex: 1"
                  ><b>Nombre:</b>
                  {{ item.student.name }}
                  {{ item.student.lastname }}
                  {{ item.student.secondlastname }}
                </span>
                <span style="flex: 1" v-if="item.student.date"><b>Edad:</b> {{ getEdad(item.student.date) }} años</span>
                <span style="flex: 1"
                  ><b>Fecha de Nacimiento:</b>
                  {{ moment(item.student.date).format("DD/MM/YYYY") }}
                </span>
              </div>
              <div style="display: flex; width: 100%; height: 4rem">
                <span style="flex: 1"><b>Género:</b> {{ item.student.gender }}</span>
                <span style="flex: 1"><b>Celular:</b> {{ item.student.phone }}</span>
                <span style="flex: 1"><b>Email:</b> {{ item.student.email }}</span>
              </div>
              <div style="display: flex; width: 100%; height: 4rem">
                <span style="flex: 1"><b>Estado:</b> {{ item.student.estadoRepublica }}</span>
                <span style="flex: 1"><b>Municipio/Delegación:</b> {{ item.student.municipaly }}</span>
                <span style="flex: 1"><b>Colonia:</b> {{ item.student.delegation }}</span>
              </div>
              <div style="display: flex; width: 100%; height: 4rem">
                <span style="flex: 1"><b>Pais:</b> {{ item.student.country }}</span>
                <span style="flex: 1"><b>CURP:</b> {{ item.student.curp }}</span>
                <span style="flex: 1"></span>
              </div>
            </div>
          </div>
        </div>
        <!-- Información Escolar -->
        <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
          <div class="flex mt-3" style="padding: 2rem">
            <div style="display: flex; flex-direction: column">
              <div style="display: flex; justify-content: left; align-items: center" v-show="item.student.status != 'exuniversitario'">
                <div class="row">
                  <div class="text-justify m-2"><b>Escuela:</b> {{ item.student.school }}</div>
                </div>
                <div class="row" v-show="item.student.status != 'exuniversitario'">
                  <div class="text-justify m-2"><b>Plantel:</b> {{ item.student.plantel }}</div>
                </div>
                <div class="row" v-show="item.student.status != 'exuniversitario'">
                  <div class="text-justify m-2"><b>Carrera:</b> {{ item.student.career }}</div>
                </div>
              </div>
            </div>
            <div style="display: flex; flex-direction: row">
              <div v-if="item.student.status == 'universitario'" style="display: flex; flex-direction: row">
                <div class="row">
                  <!-- Años cursados -->
                  <div class="text-justify m-2" v-if="item.student.yearActual == 'MedioAño'"><b>Años Cursados: </b> Medio Año</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'UnAño'"><b>Años Cursados: </b> 1 Año</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'AñoyMedio'"><b>Años Cursados: </b> 1 Año y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'DosAños'"><b>Años Cursados: </b> 2 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'DosAñosyMedio'"><b>Años Cursados: </b> 2 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Tres Años'"><b>Años Cursados: </b> 3 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Tres Años y Medio'"><b>Años Cursados: </b> 3 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Cuatro'"><b>Años Cursados: </b> 4 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Cuatro Años y Medio'"><b>Años Cursados: </b> 4 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Cinco'"><b>Años Cursados: </b> 5 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'CincoAñosyMedio'"><b>Años Cursados: </b> 5 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'SeisAños'"><b>Años Cursados: </b> 6 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'SeisAñosyMedio'"><b>Años Cursados: </b> 6 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'SieteAños'"><b>Años Cursados: </b> 7 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'SieteAñosyMedio'"><b>Años Cursados: </b> 7 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == '+OchoAños'"><b>Años Cursados: </b> Más de 8 años</div>
                </div>
                <div class="row">
                  <!-- Duración de la carrera -->
                  <div class="text-justify m-2" v-if="item.student.yearTotal == 'MedioAño'"><b>Duración de la carrera: </b> Medio Año</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'UnAño'"><b>Duración de la carrera: </b> 1 Año</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'AñoyMedio'"><b>Duración de la carrera: </b> 1 Año y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'DosAños'"><b>Duración de la carrera: </b> 2 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'DosAñosyMedio'"><b>Duración de la carrera: </b> 2 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'Tres Años'"><b>Duración de la carrera: </b> 3 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'Tres Años y Medio'"><b>Duración de la carrera: </b> 3 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'Cuatro'"><b>Duración de la carrera: </b> 4 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'Cuatro Años y Medio'"><b>Duración de la carrera: </b> 4 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'Cinco'"><b>Duración de la carrera: </b> 5 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'CincoAñosyMedio'"><b>Duración de la carrera: </b> 5 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'SeisAños'"><b>Duración de la carrera: </b> 6 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'SeisAñosyMedio'"><b>Duración de la carrera: </b> 6 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'SieteAños'"><b>Duración de la carrera: </b> 7 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'SieteAñosyMedio'"><b>Duración de la carrera: </b> 7 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == '+OchoAños'"><b>Duración de la carrera: </b> Más de 8 años</div>
                </div>
              </div>
              <div v-if="item.student.status == 'preuniversitario'" style="display: flex; flex-direction: row">
                <div class="row">
                  <!-- Años cursados -->
                  <div class="text-justify m-2" v-if="item.student.yearActual == 'Medio Año'"><b>Años Cursados:</b> Medio Año</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Un Año'"><b>Años Cursados: </b> 1 Año</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Año y Medio'"><b>Años Cursados:</b> 1 Año y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Dos Años'"><b>Años Cursados:</b> 2 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Dos Años y Medio'"><b>Años Cursados:</b> 2 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Tres Años'"><b>Años Cursados:</b> 3 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Tres Años y Medio'"><b>Años Cursados:</b> 3 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Cuatro'"><b>Años Cursados:</b> 4 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Cuatro Años y Medio'"><b>Años Cursados: </b> 4 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Cinco'"><b>Años Cursados: </b> 5 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Cinco Años y Medio'"><b>Años Cursados: </b> 5 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Seis Años'"><b>Años Cursados: </b> 6 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Seis Años y Medio'"><b>Años Cursados: </b> 6 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Siete Años'"><b>Años Cursados: </b> 7 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Siete Años y Medio'"><b>Años Cursados: </b> 7 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearActual == '+Ocho Años'"><b>Años Cursados: </b> Más de 8 años</div>
                </div>
                <div class="row">
                  <!-- Duración de la carrera -->
                  <div class="text-justify m-2" v-if="item.student.yearTotal == 'MedioAño'"><b>Duración de bachillerato: </b> Medio Año</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'UnAño'"><b>Duración de bachillerato: </b> 1 Año</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'AñoyMedio'"><b>Duración de bachillerato: </b> 1 Año y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'DosAños'"><b>Duración de bachillerato: </b> 2 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'DosAñosyMedio'"><b>Duración de bachillerato: </b> 2 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'Tres Años'"><b>Duración de bachillerato: </b> 3 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'Tres Años y Medio'"><b>Duración de bachillerato: </b> 3 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'Cuatro'"><b>Duración de bachillerato: </b> 4 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'Cuatro Años y Medio'"><b>Duración de bachillerato: </b> 4 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'Cinco'"><b>Duración de bachillerato: </b> 5 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'CincoAñosyMedio'"><b>Duración de bachillerato: </b> 5 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'SeisAños'"><b>Duración de bachillerato: </b> 6 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'SeisAñosyMedio'"><b>Duración de bachillerato: </b> 6 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'SieteAños'"><b>Duración de bachillerato: </b> 7 Años</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'SieteAñosyMedio'"><b>Duración de bachillerato: </b> 7 Años y medio</div>
                  <div class="text-justify m-2" v-else-if="item.student.yearTotal == '+OchoAños'"><b>Duración de bachillerato: </b> Más de 8 años</div>
                </div>
              </div>
              <div class="row" v-show="item.student.status != 'exuniversitario'">
                <div class="text-justify m-2"><b>Promedio:</b> {{ item.student.promedio }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- Idiomas -->
        <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">
          <div class="flex mt-3" style="padding: 2rem">
            <div class="row" v-for="(exp, i) in item.student.idiomas" :key="i + 1">
              <div class="col-4 text-justify m-2">
                <b>Idioma {{ i + 1 }}</b> {{ exp.idioma }}
              </div>
              <div class="col-4 text-justify m-2"><b>Nivel:</b> {{ exp.nivel }}</div>
            </div>
          </div>
        </div>
        <!-- Hobbies -->
        <div class="tab-pane fade" id="disabled-tab-pane" role="tabpanel" aria-labelledby="disabled-tab" tabindex="0">
          <div class="flex mt-3" style="padding: 2rem">
            <div class="row mb-2" v-for="(exp, i) in item.student.hobbies" :key="i + 1">
              <div class="col text-justify"><b>Hobbie: </b> {{ exp.name }}</div>
            </div>
            <div class="row mb-2">
              <div class="col text-justify" v-if="item.student.hobbieWrite1">
                <b>Hobbie:</b>
                {{ item.student.hobbieWrite1 }}
              </div>
            </div>
            <div class="row mb-2">
              <div class="col text-justify" v-if="item.student.hobbieWrite2">
                <b>Hobbie:</b>
                {{ item.student.hobbieWrite2 }}
              </div>
            </div>
          </div>
        </div>
        <!-- Experiencia -->
        <div class="tab-pane fade" id="experiencia-tab-pane" role="tabpanel" aria-labelledby="experiencia-tab" tabindex="0">
          <div class="flex" style="padding: 2rem">
            <div v-for="(exp, i) in item.student.experiencia" :key="i + 1">
              <div v-if="exp.experienciaCompany" style="display: flex; flex-direction: row; justify-content: left; align-items: left; margin-top: 10px">
                <div class="row">
                  <div class="text-justify m-2">
                    <b>Nombre de la Empresa:</b>
                    {{ exp.experienciaCompany }}
                  </div>
                </div>
                <div class="row">
                  <div class="text-justify m-2">
                    <b>Puesto:</b>
                    {{ exp.experienciaPuesto }}
                  </div>
                </div>
              </div>
              <div v-if="exp.experienciaCompany" style="display: flex; flex-direction: row; justify-content: left; align-items: left">
                <div class="row">
                  <div class="text-justify m-2"><b>Salario: </b> ${{ exp.experienciaSalario }}</div>
                </div>
                <div class="row">
                  <div class="text-justify m-2">
                    <b>Fecha de Inicio:</b>
                    {{ exp.experienciaFechaInicio }}
                  </div>
                </div>
                <div class="row">
                  <div v-if="exp.experienciaFechaFin" class="text-justify m-2">
                    <b>Fecha de Termino:</b>
                    {{ exp.experienciaFechaFin }}
                  </div>
                </div>
              </div>
              <div v-if="exp.experienciaCompany" style="display: flex; flex-direction: row; justify-content: left; align-items: left">
                <div class="row">
                  <div v-if="exp.experienciaLogros" class="text-justify m-2">
                    <b>Actividades realizadas:</b>
                    {{ exp.experienciaLogros }}
                  </div>
                </div>
              </div>
              <div v-else class="row mt-3 mb-2" style="margin-left: 10px">
                <span class="text-center">Sin Experiencia Previa</span>
              </div>
              <hr />
            </div>
          </div>
        </div>
        <!-- Documentos -->
        <div class="tab-pane fade" id="documents-tab-pane" role="tabpanel" aria-labelledby="documents-tab" tabindex="0">
          <!-- Progreso de llenado de documentos -->
          <div style="padding: 2rem">
            <span style="font-style: italic">Progreso de llenado</span>
            <div v-for="(item, index) in dataPostulate" :key="index">
                      <div v-if="allDocumentsPresent(item)">
                          <div class="progress-bar text-bg-success" style="width: 100%">100%</div>
                      </div>
                      <div v-else>
                          <div class="progress-bar text-bg-warning" :style="{ width: calculateProgress(item) + '%' }">{{ calculateProgress(item) }}%</div>
                      </div>
                  </div>
          </div>
          <div style="display: flex; flex-direction: column; align-items: flex-start; padding: 2rem">
            <!-- 1 CV -->
            <div style="display: flex; justify-content: center; align-items: center" v-if="item.student?.filecv">
              <a class="J-btn auto" title="CV" target="_blank" :href="item.student.filecv?.link">
                <box-icon name="check" color="#39811d"></box-icon>
                <small>CV</small>
              </a>
            </div>
            <div style="display: flex; justify-content: center; align-items: center" v-else>
              <box-icon name="x-circle" color="#e53935"></box-icon>
              <small>CV</small>
            </div>

            <!-- 2 Comp de Estudios -->
            <div style="display: flex; justify-content: center; align-items: center" v-if="item.student?.filecomprobantes">
              <a class="J-btn auto" title="Comprobante de Estudios" target="_blank" :href="item.student?.filecomprobantes?.link">
                <box-icon name="check" color="#39811d"></box-icon>
                <small>Comprobante de Estudios</small>
              </a>
            </div>
            <div style="display: flex; justify-content: center; align-items: center" v-else>
              <box-icon name="x-circle" color="#e53935"></box-icon>
              <small>Comprobante de Estudios</small>
            </div>

            <!-- 3 Comp Domicilio -->
            <div style="display: flex; justify-content: center; align-items: center" v-if="item?.document_comprobante">
              <a class="J-btn auto" title="Comprobante de Domicilio" target="_blank" :href="item?.document_comprobante?.link">
                <box-icon name="check" color="#39811d"></box-icon>
                <small>Comprobante de Domicilio</small>
              </a>
            </div>
            <div style="display: flex; justify-content: center; align-items: center" v-else>
              <box-icon name="x-circle" color="#e53935"></box-icon>
              <small>Comprobante de Domicilio</small>
            </div>

            <!-- 4 INE -->
            <div style="display: flex; justify-content: center; align-items: center" v-if="item?.document_ine">
              <a class="J-btn auto" title="INE" target="_blank" :href="item?.document_ine?.link">
                <box-icon name="check" color="#39811d"></box-icon>
                <small>INE</small>
              </a>
            </div>
            <div style="display: flex; justify-content: center; align-items: center" v-else>
              <box-icon name="x-circle" color="#e53935"></box-icon>
              <small>INE</small>
            </div>

            <!-- 5 Acta -->
            <div style="display: flex; justify-content: center; align-items: center" v-if="item?.document_acta">
              <a class="J-btn auto" title="Acta de Nacimiento" target="_blank" :href="item?.document_acta?.link">
                <box-icon name="check" color="#39811d"></box-icon>
                <small>Acta de Nacimiento</small>
              </a>
            </div>
            <div style="display: flex; justify-content: center; align-items: center" v-else>
              <box-icon name="x-circle" color="#e53935"></box-icon>
              <small>Acta de Nacimiento</small>
            </div>

            <!-- 6 CURP -->
            <div style="display: flex; justify-content: center; align-items: center" v-if="item?.document_curp">
              <a class="J-btn auto" title="CURP" target="_blank" :href="item?.document_curp?.link">
                <box-icon name="check" color="#39811d"></box-icon>
                <small>CURP</small>
              </a>
            </div>
            <div style="display: flex; justify-content: center; align-items: center" v-else>
              <box-icon name="x-circle" color="#e53935"></box-icon>
              <small>CURP</small>
            </div>

            <!-- 7 RFC -->
            <div style="display: flex; justify-content: center; align-items: center" v-if="item?.document_rfc">
              <a class="J-btn auto" title="Constancia de Situación Fiscal" target="_blank" :href="item?.document_rfc?.link">
                <box-icon name="check" color="#39811d"></box-icon>
                <small>Constancia de Situación Fiscal</small>
              </a>
            </div>
            <div style="display: flex; justify-content: center; align-items: center" v-else>
              <box-icon name="x-circle" color="#e53935"></box-icon>
              <small>Constancia de Situación Fiscal</small>
            </div>

            <!-- 8 imss/nss -->
            <div style="display: flex; justify-content: center; align-items: center" v-if="item?.document_seguro">
              <a class="J-btn auto" title="Solicitud de poliza contra accidentes" target="_blank" :href="item?.document_seguro?.link">
                <box-icon name="check" color="#39811d"></box-icon>
                <small>Seguro facultativo/SGMM</small>
              </a>
            </div>
            <div style="display: flex; justify-content: center; align-items: center" v-else>
              <box-icon name="x-circle" color="#e53935"></box-icon>
              <small>Seguro facultativo/SGMM</small>
            </div>

            <!-- 9 Hoja de datos personales -->
            <div style="display: flex; justify-content: center; align-items: center" v-if="item?.document_personales">
              <a class="J-btn auto" title="Hoja de datos personales" target="_blank" :href="item?.document_personales?.link">
                <box-icon name="check" color="#39811d"></box-icon>
                <small>Hoja de datos personales</small>
              </a>
            </div>
            <div style="display: flex; justify-content: center; align-items: center" v-else>
              <box-icon name="x-circle" color="#e53935"></box-icon>
              <small>Hoja de datos personales</small>
            </div>

            <!-- 10 seguro de accidentes -->
            <div style="display: flex; justify-content: center; align-items: center" v-if="item?.document_accidentes">
              <a class="J-btn auto" title="Seguro de accidentes" target="_blank" :href="item?.document_accidentes?.link">
                <box-icon name="check" color="#39811d"></box-icon>
                <small>Solicitud de poliza contra accidentes</small>
              </a>
            </div>
            <div style="display: flex; justify-content: center; align-items: center" v-else>
              <box-icon name="x-circle" color="#e53935"></box-icon>
              <small>Solicitud de poliza contra accidentes</small>
            </div>

            <!-- 11 historial académico -->
            <div style="display: flex; justify-content: center; align-items: center" v-if="item?.document_historialAcademico">
              <a class="J-btn auto" title="Historial Académico" target="_blank" :href="item?.document_historialAcademico?.link">
                <box-icon name="check" color="#39811d"></box-icon>
                <small>Historial Académico</small>
              </a>
            </div>
            <div style="display: flex; justify-content: center; align-items: center" v-else>
              <box-icon name="x-circle" color="#e53935"></box-icon>
              <small>Historial Académico</small>
            </div>

            <!-- 12 horario escolar -->
            <div style="display: flex; justify-content: center; align-items: center" v-if="item?.document_horario">
              <a class="J-btn auto" title="Horario Escolar" target="_blank" :href="item?.document_horario?.link">
                <box-icon name="check" color="#39811d"></box-icon>
                <small>Horario Escolar</small>
              </a>
            </div>
            <div style="display: flex; justify-content: center; align-items: center" v-else>
              <box-icon name="x-circle" color="#e53935"></box-icon>
              <small>Horario Escolar</small>
            </div>

            <!-- 13 Comp de Inscripción  -->
            <div style="display: flex; justify-content: center; align-items: center" v-if="item?.document_ComprobanteInscripcion">
              <a class="J-btn auto" title="Comprobante de inscripción" target="_blank" :href="item?.document_ComprobanteInscripcion?.link">
                <box-icon name="check" color="#39811d"></box-icon>
                <small>Comprobante de Inscripción</small>
              </a>
            </div>
            <div style="display: flex; justify-content: center; align-items: center" v-else>
              <box-icon name="x-circle" color="#e53935"></box-icon>
              <small>Comprobante de Inscripción</small>
            </div>
          </div>
        </div>
        <!-- Convenios -->
        <div class="tab-pane fade" id="convenios-tab-pane" role="tabpanel" aria-labelledby="convenios-tab" tabindex="0" style="padding: 1rem; max-height: 570px; overflow: scroll">
          <div>
            <!--  -->
          <div>
            <div style="margin-bottom: 2rem; width: 100%">
            <span style="font-style: italic">Progreso de llenado</span>
            <div v-if="item.document_convenio1 && !item.document_convenio2 && !item.document_convenio3" class="progress-bar text-bg-danger" style="width: 33%">33%</div>
            <div v-else-if="item.document_convenio2 && !item.document_convenio1 && !item.document_convenio3" class="progress-bar text-bg-warning" style="width: 33%">33%</div>
            <div v-else-if="item.document_convenio3 && !item.document_convenio1 && !item.document_convenio2" class="progress-bar text-bg-warning" style="width: 33%">33%</div>
            <div v-else-if="item.document_convenio1 && item.document_convenio2 && !item.document_convenio3" class="progress-bar text-bg-warning" style="width: 66%">66%</div>
            <div v-else-if="item.document_convenio1 && !item.document_convenio2 && item.document_convenio3" class="progress-bar text-bg-warning" style="width: 66%">66%</div>
            <div v-else-if="!item.document_convenio1 && item.document_convenio2 && item.document_convenio3" class="progress-bar text-bg-warning" style="width: 66%">66%</div>
            <div v-else-if="item.document_convenio1 && item.document_convenio2 && item.document_convenio3" class="progress-bar text-bg-success" style="width: 100%">100%</div>
          </div>
          <div style="display: flex; flex-direction: column; justify-content: center; align-items: flex-start; padding: 2rem">
            <div style="display: flex; justify-content: center; align-items: center" v-if="item?.document_convenio1">
              <a class="J-btn auto" title="Convenio 1" target="_blank" :href="item?.document_convenio1?.link">
                <box-icon name="check" color="#39811d"></box-icon>
                <small>Convenio 1</small>
              </a>
            </div>
            <div style="display: flex; justify-content: center; align-items: center" v-if="item?.document_convenio2">
              <a class="J-btn auto" title="Convenio 2" target="_blank" :href="item?.document_convenio2?.link">
                <box-icon name="check" color="#39811d"></box-icon>
                <small>Convenio 2</small>
              </a>
            </div>
            <div style="display: flex; justify-content: center; align-items: center" v-if="item?.document_convenio3">
              <a class="J-btn auto" title="Convenio 3" target="_blank" :href="item?.document_convenio3?.link">
                <box-icon name="check" color="#39811d"></box-icon>
                <small>Carta de confidencialidad, privacidad y otros</small>
              </a>
            </div>
          </div>

          <div v-show="!item?.document_convenio1 && !item?.document_convenio2 && !item?.document_convenio3" style="display: flex; justify-content: center; align-items: center">
            <h4>Aún no tienes convenios para ver</h4>
            <img src="/images/profile.jpg" alt="Logo Company" class="rounded-circle" loading="eager" style="width: 20%; aspect-ratio: 1/1; object-fit: cover; object-position: center" />
          </div>
        </div>
            <!--  -->
            <!-- <div v-show="item?.document_convenio1 && item?.document_convenio2 && item?.document_convenio3" style="display: flex; flex-direction: column; justify-content: center; align-items: flex-start; padding: 2rem">
              <div style="margin-bottom: 2rem; width: 100%">
                <span style="font-style: italic">Progreso de llenado</span>
                <div v-if="item.document_convenio1 && !item.document_convenio2 && !item.document_convenio3" class="progress-bar text-bg-danger" style="width: 33%">33%</div>
                <div v-else-if="item.document_convenio2 && !item.document_convenio3" class="progress-bar text-bg-warning" style="width: 66%">66%</div>
                <div v-else-if="item.document_convenio3" class="progress-bar text-bg-success" style="width: 100%">100%</div>
              </div>
              <div style="display: flex; justify-content: center; align-items: center" v-if="item?.document_convenio1">
                <a class="J-btn auto" title="Convenio " target="_blank" :href="item?.document_convenio1?.link">
                  <box-icon name="check" color="#39811d"></box-icon>
                  <small>Convenio 1</small>
                </a>
              </div>
              <div style="display: flex; justify-content: center; align-items: center" v-if="item?.document_convenio2">
                <a class="J-btn auto" title="Convenio 2" target="_blank" :href="item?.document_convenio2?.link">
                  <box-icon name="check" color="#39811d"></box-icon>
                  <small>Convenio 2</small>
                </a>
              </div>
              <div style="display: flex; justify-content: center; align-items: center" v-if="item?.document_convenio3">
                <a class="J-btn auto" title="Convenio3" target="_blank" :href="item?.document_convenio3?.link">
                  <box-icon name="check" color="#39811d"></box-icon>
                  <small>Carta de confidencialidad, privacidad y otros</small>
                </a>
              </div>
            </div>
            <div v-show="!item?.document_convenio1 && !item?.document_convenio2 && !item?.document_convenio3" style="display: flex; justify-content: center; align-items: center">
              <h4>Aún no tienes convenios para ver</h4>
              <img src="/images/profile.jpg" alt="Logo Company" class="rounded-circle" loading="eager" style="width: 20%; aspect-ratio: 1/1; object-fit: cover; object-position: center" />
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="js">
  import * as ClientService from "../../services/client"
  import moment from 'moment-timezone';
  moment.locale("es");
  
  export default {
      data: () => ({
          moment: moment,
          userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          file_name: null,
          file: null,
          dataPostulate: [],
          dataPostulantetemp: [],
          informationPostulante: {},
          informationStudent: {},
          informationUser: {},
          searchInput2: "",
          id: "",
          show: "",
          idAdministration: "",                  		
          position: "",
          salary: "",
          schoolEnrollment: "",
          company: "",
          mentor: "",
          schoolSemester: "",
          department: "",
          emailMentor: "",
          titulacion: "",
          ubicationCompany: "",
          bank: "",
          policyNumberSchool: "",
          costCenter: "",
          bankAccount: "",
          policyNumber: "",
          nameCostCenter: "",			
          dateHired: "",
          insuranceCarrier: "",
          emailCorporative: "",
          dateFirstPeriod: "",
          rfc: "",
          statusActually: "",
          secondEndDate: "",
          antiquityPractices: "",
          dateSecondPeriod: "",
          nameContact: "",
          relationship: "",
          numberContact: "",
          nameContactTwo: "",
          relationshipTwo: "",
          numberContactTwo: "",
          response: {
              loading: false
          },
          id_vacante: "",
          enableInput: true,
          enableInput2: true
      }),
      mounted() {
          this.id = this.$route.params.vacante;
          const postulante = this.$route.params.postulante;
          this.id_vacante = this.$route.params.id;
          this.getPostulates(this.id, postulante);
      },  
      methods: {
          async getPostulates(vacante, postulante) {
              try {
                  let resp = await ClientService.CandidateContratado({ id: vacante, postulante: postulante });
                  let { data } = resp.data;
                  this.dataPostulate = data;
                  console.log(data)
                  let { student, user, _id, status } = data;
                  this.idPostulante = _id;
                  this.informationStudent = student;
                  this.informationUser = user;
                  this.statusPostulante = status;
              } catch (e) {
                  console.log(e.message);
              }
          },
          //TODO: Calcular Edad De Postulantes
          getEdad(dateString) {
              let hoy = new Date()
              let fechaNacimiento = new Date(dateString)
              let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
              let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
              if (
                  diferenciaMeses < 0 ||
                  (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
              ) {
                  edad--
              }
              return edad
          },
          viewPostulates(item) {
              let { student, user, _id, status } = item;
              this.idPostulante = _id;
              this.informationStudent = student;
              this.informationUser = user;
              this.statusPostulante = status;
          },
          openLinkedin(url) {
              let word = "https://"
              url.includes(word) ? window.open(url) : window.open("https://" + url);
          },
          datosAdministracion(){
          this.dataPostulate.forEach((postulate) => {
          this.idAdministration = postulate.idAdministration;
          this.position = postulate.position;
          this.salary = postulate.salary;
          this.schoolEnrollment = postulate.schoolEnrollment;
          this.company = postulate.company;
          this.mentor = postulate.mentor;
          this.schoolSemester = postulate.schoolSemester;
          this.department = postulate.department;
          this.emailMentor = postulate.emailMentor;
          this.titulacion = postulate.titulacion;
          this.ubicationCompany = postulate.ubicationCompany;
          this.bank = postulate.bank;
          this.policyNumberSchool = postulate.policyNumberSchool;
          this.costCenter = postulate.costCenter;
          this.bankAccount = postulate.bankAccount;
          this.policyNumber = postulate.policyNumber;
          this.nameCostCenter = postulate.nameCostCenter;
          if (postulate.dateHired) {
              const formattedDate1 = postulate.dateHired.split('T')[0];
              this.dateHired = formattedDate1;
          } else {
              this.dateHired = '';
          }
          if (postulate.dateFirstPeriod) {
              const formattedDate2 = postulate.dateFirstPeriod.split('T')[0];
              this.dateFirstPeriod = formattedDate2;
          } else {
              this.dateFirstPeriod = '';
          }
          if (postulate.dateSecondPeriod) {
              const formattedDate3 = postulate.dateSecondPeriod.split('T')[0];
              this.dateSecondPeriod = formattedDate3;
          } else {
              this.dateSecondPeriod = '';
          }
          if (postulate.secondEndDate) {
              const formattedDate4 = postulate.secondEndDate.split('T')[0];
              this.secondEndDate = formattedDate4;
          } else {
              this.secondEndDate = '';
          }
                this.insuranceCarrier = postulate.insuranceCarrier;
                this.emailCorporative = postulate.emailCorporative;
                this.rfc = postulate.rfc;
                this.statusActually = postulate.statusActually;
                this.antiquityPractices = postulate.antiquityPractices;
                this.nameContact = postulate.nameContact;
                this.relationship = postulate.relationship;
                this.numberContact = postulate.numberContact;
                this.nameContactTwo = postulate.nameContactTwo;
                this.relationshipTwo = postulate.relationshipTwo;
                this.numberContactTwo = postulate.numberContactTwo;
                this.evaluation = postulate.evaluation;
                this.comments = postulate.comments;
              });
          },
          formatBeca() {
            const becaDeValue = this.salary.trim();
            if (becaDeValue !== "") {
              const parsedValue = parseInt(becaDeValue);
              if (!isNaN(parsedValue)) {
                this.salary = parsedValue.toLocaleString("es-MX", {
                  style: "currency",
                  currency: "MXN",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              }
            }
          },
          onlyNumber(event) {
            const charCode = event.charCode;
            if (charCode < 48 || charCode > 57) {
              event.preventDefault();
            }
          },
          allDocumentsPresent(item) {
                  return item.student.filecv &&
                      item.student?.filecomprobantes &&
                      item.document_acta &&
                      item.document_ine &&
                      item.document_comprobante &&
                      item.document_curp &&
                      item.document_rfc &&
                      item.document_seguro &&
                      item.document_personales &&
                      item.document_accidentes &&
                      item.document_historialAcademico &&
                      item.document_horario &&
                      item.document_ComprobanteInscripcion;
          },
          // Calcula el progreso para un postulante específico
          calculateProgress(item) {
                  const totalDocuments = 13; // Total de documentos requeridos
                  let presentDocuments = 0;
                  if (item.student.filecv) presentDocuments++;
                  if (item.student?.filecomprobantes) presentDocuments++;
                  if (item.document_acta) presentDocuments++;
                  if (item.document_ine) presentDocuments++;
                  if (item.document_comprobante) presentDocuments++;
                  if (item.document_curp) presentDocuments++;
                  if (item.document_rfc) presentDocuments++;
                  if (item.document_seguro) presentDocuments++;
                  if (item.document_personales) presentDocuments++;
                  if (item.document_accidentes) presentDocuments++;
                  if (item.document_historialAcademico) presentDocuments++;
                  if (item.document_horario) presentDocuments++;
                  if (item.document_ComprobanteInscripcion) presentDocuments++;
                  return Math.ceil((presentDocuments / totalDocuments) * 100);
          },
          comeback(){
            this.$router.push('/administration/clients/list/contratados')
          },  
          getAntiguedad(dateHired, dateSecondPeriod) {
              const startDate = moment(dateHired).startOf('day');
              let endDate = dateSecondPeriod ? moment(dateSecondPeriod).startOf('day') : moment().startOf('day'); 
              if (dateSecondPeriod && endDate < startDate) {
                  return 0;
              }
              if (!dateSecondPeriod) {
                  endDate = endDate.subtract(1, 'day'); 
              }
              const tiempoDiferencia = endDate.diff(startDate, 'days');
              return Math.max(tiempoDiferencia, 0); 
          }
      },
  };
  </script>
  
  <style land="scss" scoped>
  @import "../../styles/informacionpostulantes.scss";
  </style>
  